<template>
	<CommonPage
		:wrapper="true"
		backTitle="Disclaimer"
		:back="true">
		<div class="def">
			<h2>General Disclaimer</h2>
			<ul>
				<li>The content on dailyhistory.online is for informational and educational purposes only. While we strive for accuracy, we make no representations or warranties about the completeness or reliability of any information.</li>
			</ul>

			<h2>Limitation of Liability</h2>
			<ul>
				<li>
					<strong>Use at Your Own Risk:</strong>
					Use the information on this site at your own risk. We are not responsible for any errors or omissions.
				</li>
				<li>
					<strong>No Liability:</strong>
					We are not liable for any direct, indirect, incidental, or consequential damages arising from the use of the site.
				</li>
			</ul>

			<h2>External Links</h2>
			<ul>
				<li>Our site may contain links to external websites. We are not responsible for the content, accuracy, or practices of these sites. Visiting linked sites is at your own risk.</li>
			</ul>

			<h2>Indemnification</h2>
			<ul>
				<li>You agree to indemnify and hold dailyhistory.online harmless from any claims, losses, or damages arising from your use of the site or violation of these terms.</li>
			</ul>

			<h2>Changes to Disclaimer</h2>
			<ul>
				<li>We reserve the right to modify this disclaimer at any time. Continued use of the site indicates acceptance of any changes.</li>
			</ul>
		</div>
	</CommonPage>
</template>

<script>
const title = 'Disclaimer | dailyhistory.online'
const description = 'Read the Disclaimer for dailyhistory.online. Learn about the limitations of liability regarding our historical content.'

export default {
	name: 'privacy',
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
	mounted() {
		document.querySelector('body').style.backgroundColor = '#fcfbfb'
	},
}
</script>
